.botonPagina {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.botonPagina li {
  list-style: none;
}

.botonPagina li button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  background-color: rgba(96, 96, 96, 0.732);
  transition: all 0.2s ease;
  cursor: pointer;
}

.botonPagina li button:focus {
  width: 43px;
  height: 43px;
  transition: all 0.2s ease;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.botonPagina button {
  width: 100px;
  height: 45px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.2s ease;
  cursor: pointer;
}

.botonPagina button:focus {
  width: 100px;
  height: 43px;
  transition: all 0.2s ease;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

@media (max-width: 475px) {
  .botonPagina {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .botonPagina li {
    list-style: none;
    margin-left: 10px;
    margin-right: 10px;
  }

  .botonPagina li button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 15px;
    font-weight: 700;
    background-color: rgba(96, 96, 96, 0.732);
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .botonPagina li button:focus {
    width: 37px;
    height: 37px;
    transition: all 0.2s ease;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .botonPagina button {
    width: 60px;
    height: 30px;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 700;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .botonPagina button:focus {
    width: 70px;
    height: 30px;
    transition: all 0.2s ease;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
}