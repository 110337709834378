.contenedorGeneral .seccionAdmin img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: rgb(165, 165, 165);
}

.contenedorGeneral .accionesAdmin {
  display: flex;
  margin: auto;
  width: 90%;
  height: 100%;
  border: solid 2px rgb(0, 0, 0);
  /* background-color: #e5e5e5; */
  background: linear-gradient(180deg, #FFB7B7 0%, #727272 100%), radial-gradient(60.91% 100% at 50% 0%, #FFD1D1 0%, #260000 100%), linear-gradient(127.43deg, #00FFFF 0%, #FFFFFF 100%), radial-gradient(100.22% 100% at 70.57% 0%, #FF0000 0%, #00FFE0 100%), linear-gradient(64.82deg, #DBFF00 0%, #3300FF 100%);
  background-blend-mode: screen, overlay, color-burn, color-dodge, normal;
}

.ayuda {
  display: flex;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ayuda h2 {
  padding-top: 50px;
  color: black;
  font-size: 30px;
  text-decoration: underline;
  padding-bottom: 8px;
}

.ayuda h3 {
  padding-top: 50px;
  color: black;
  font-size: 30px;
  text-decoration: underline;
  padding-bottom: 8px;
}

.ayuda ol {
  width: 50%;
  color: black;
  font-weight: 700;
  font-size: 20px;
  text-align: justify;
}

.ayuda p {
  width: 50%;
  font-size: 16px;
  color: black;
  padding-top: 10px;
  text-align: justify;
}

.ayuda h5 {
  margin-top: 20px;
  color: red;
  font-size: 18px;
}

.ayuda h6 {
  margin-top: 20px;
  color: green;
  font-size: 18px;
}

@media (max-width: 1080px) {
  .contenedorGeneral .seccionAdmin img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgb(165, 165, 165);
    width: 600px;
  }
}

@media (max-width: 805px) {
  .contenedorGeneral .seccionAdmin img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgb(165, 165, 165);
    width: 500px;
  }
}

@media (max-width: 475px) {
  .contenedorGeneral .seccionAdmin img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: rgb(165, 165, 165);
    width: 300px;
  }
}