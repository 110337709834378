.contenedorBuscador {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  flex-direction: column;
  /* background-color: #d0aeae; */
  background-color: #e5e5e5;
  /* background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #ffb2b2 calc(40% + 1px), #ffb2b2 60%, #ff5e5e calc(60% + 1px), #ff5e5e 72%, #ff3e3e calc(72% + 1px), #ff3e3e 100%); */
  color: #09010B;
}

.contenedorBuscador h2 {
  font-size: 50px;
  margin-top: 5%;
  font-family: var(--fuenteLetras);
  font-weight: 700;
}

.form {
  background-color: #9e9e9e;
  color: #09010B;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 96%;
}

.inputS {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
  border-radius: 0;
  outline: none;
  border: solid transparent 2px;
  height: 30px;
  font-weight: 700;
  text-align: center;
}

.form {
  /* background-color: #d0aeae; */
  background-color: #e5e5e5;
  /* background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #ffb2b2 calc(40% + 1px), #ffb2b2 60%, #ff5e5e calc(60% + 1px), #ff5e5e 72%, #ff3e3e calc(72% + 1px), #ff3e3e 100%); */

  color: #09010B;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 96%;
}

.btnBuscar {
  width: 100%;
  margin-bottom: 5%;
  border-radius: 0;
  background-color: #F9F9F9;
  font-family: var(--fuenteMenu);
  font-weight: 700;
  transition: all .2s ease;
}

.btnBuscar:hover {
  background-color: royalblue;
  transition: all .2s ease;
}

.btnRecargar {
  width: 100%;
  border-radius: 0;
  background-color: #F9F9F9;
}

.btnRecargar:hover {
  background-color: royalblue;
  transition: all .2s ease;
}


.contenedorBotones {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
  padding: 20px;
}

.contenedorProductos {
  padding: 10px;
  background-color: #e5e5e5;
  height: 100%;
}

.productos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
}


.loaderfondo {
  background-color: #e5e5e5;
  width: 100%;
  height: 50vh;
}

.loader {
  /* color of spining  */
  width: 50px;
  height: 50px;
  position: absolute;
  top: 80%;
  left: 50%;
  background-color: #09010B;
  color: #BB1818;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #BB1818;
}

.loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.loader:after {
  border: 10px solid #979797;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 475px) {
  .contenedorBuscador h2 {
    font-size: 25px;
  }
}