@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}

.textoArriba {
  color: white;
  background-color: #09010B;
  width: 100%;
  height: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.textoArriba p {
  padding: 10px;
}

.contenedorLogo {
  width: 100%;
  border-bottom: 1px #979797 solid;
}

.contenedorLogo img {
  width: 30%;
  height: 20%;
}

.contenedorProducto {
  width: 98.5%;
  height: 100%;
  background-color: #e5e5e5;
  padding: 8px;
}

@keyframes scale {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.btnVolver {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 50px;
  width: 200px;
  height: 40px;
  border: 1px rgba(0, 0, 0, 0.406) solid;
  border-radius: 5px;
  margin-bottom: 2%;
}

.contenedorCard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  width: 80%;
  background-color: white;
  padding: 40px;
  display: flex;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  transform: scale(0);
  animation: scale 0.3s ease forwards;
}



.cardNombre {
  font-size: 30px;
  text-transform: uppercase;
  font-family: var(--fuenteMenu);
}

.cardImg {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
}

.cardCategoriaTotal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
}

.cardCategoria {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-bottom: 2%;
  font-family: var(--fuenteMenu);
}

.cardCategoria b {
  font-size: 20px;
  font-weight: 700;
  text-align: end;
}

.cardSeccion {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-bottom: 2%;
  font-family: var(--fuenteMenu);
}

.cardSeccion b {
  font-size: 20px;
  font-weight: 700;
}

.cardDescripcionTotal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
}

.cardDescripcion {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
  font-weight: 400;
  text-align: justify;
  font-family: var(--fuenteMenu);
}

.cardDescripcion b {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.interes {
  margin-top: 2%;
}

.interes p {
  font-size: 18px;
  font-weight: 600;
}

.wsp {
  width: 70px;
  height: 70px;
  border: solid 1px white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  background-color: #25d366;
  border-radius: 5px;
  transition: all 0.3s ease;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 100%;
}

.wsp:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease;
  background-color: #25d365b4;
}

.wspA {
  color: white;
  margin-top: 2%;
  font-size: 28px;
}

@media (max-width: 800px) {
  .contenedorCard {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 70%;
    background-color: white;
    padding: 40px;
    display: flex;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    transform: scale(0);
    animation: scale 0.3s ease forwards;
  }

  .cardImg {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
  }
}

@media (max-width: 475px) {
  .textoArriba {
    color: white;
    background-color: #09010B;
    width: 100%;
    height: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: justify;
    flex-direction: column;
  }

  .contenedorLogo {
    width: 100%;
    border-bottom: 1px #979797 solid;
  }

  .contenedorLogo img {
    width: 60%;
    height: 10%;
  }

  .contenedorProducto {
    width: 98.5%;
    height: 100%;
    background-color: #e5e5e5;
    padding: 8px;
  }

  .contenedorCard {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 70%;
    background-color: white;
    padding: 40px;
    display: flex;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    transform: scale(0);
    animation: scale 0.3s ease forwards;
  }

  .cardNombre {
    font-size: 30px;
    text-transform: uppercase;
    font-family: var(--fuenteMenu);
  }

  .cardImg {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 15px;
  }


  .cardCategoria {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 2%;
    font-family: var(--fuenteMenu);
  }

  .cardCategoria b {
    font-size: 30px;
    font-weight: 700;
  }

  .cardSeccion {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    margin-bottom: 2%;
    font-family: var(--fuenteMenu);
  }

  .cardSeccion b {
    font-size: 30px;
    font-weight: 700;
  }

  .cardDescripcion {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
    font-weight: 400;
    text-align: justify;
    font-family: var(--fuenteMenu);
    width: 80%;
    margin: auto;
  }

  .cardDescripcion b {
    font-size: 30px;
    font-weight: 700;
    text-align: justify;
  }

  .btnVolver {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 16px;
    margin-top: 50px;
    width: 200px;
    height: 30px;
    border: 1px rgba(0, 0, 0, 0.406) solid;
    border-radius: 5px;
    margin-bottom: 2%;
  }
}