:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}


.navMenu {
  display: flex;
  align-items: center;
  margin: auto;
  flex-direction: row;
  background-color: white;
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  justify-content: space-around;
  margin-bottom: 2%;
}

.navMenu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: row;
  width: 100%;
}

.navMenu ul li {
  list-style: none;
  padding: 15px;
  font-size: 16px;
  font-weight: 700;
  color: var(--colorNegro);
  text-transform: uppercase;
}

.navMenu ul li:hover {
  border-top: 1px black solid;
  color: var(--colorNegro);
  font-weight: 700;
}

.navMenu ul li:active {
  border-top: 2px black solid;
}

.menuDesplegableRevest ul {
  display: none;
  min-width: 140px;
  position: absolute;
  background-color: white;
  width: 50%;
  top: 280px;
  right: 0%;
  transition: all 1s ease;
  z-index: 100000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.menuDesplegableRevest:hover>ul {
  display: block;
  width: 10%;
  right: 55%;
  transition: all 1s ease;
  z-index: 100000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  text-align: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
}

.menuDesplegableRevest ul li {
  border-bottom: solid 1px black;
  position: relative;
  cursor: pointer;
  padding: 5px;
  top: 0px;
}

.menuDesplegableRevest ul li:hover {
  background-color: #e5e5e5;
}

.menuDesplegable ul {
  display: none;
  min-width: 140px;
  position: absolute;
  background-color: white;
  width: 50%;
  top: 280px;
  right: 0%;
  transition: all 1s ease;
  z-index: 100000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.menuDesplegable:hover>ul {
  display: block;
  width: 20%;
  right: 40%;
  transition: all 1s ease;
  z-index: 100000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  text-align: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
}

.menuDesplegable ul li {
  border-bottom: solid 1px black;
  position: relative;
  cursor: pointer;
  padding: 5px;
  top: 0px;
}

.menuDesplegable ul li:hover {
  background-color: #e5e5e5;
}

.menuDesplegableDos ul {
  display: none;
  min-width: 140px;
  position: absolute;
  background-color: white;
  width: 50%;
  top: 280px;
  right: 0%;
  transition: all 1s ease;
  z-index: 100000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.menuDesplegableDos:hover>ul {
  display: block;
  width: 20%;
  right: 30%;
  transition: all 1s ease;
  z-index: 100000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  text-align: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
}

.menuDesplegableDos ul li {
  border-bottom: solid 1px black;
  position: relative;
  cursor: pointer;
  padding: 5px;
  top: 0px;
}

.menuDesplegableDos ul li:hover {
  background-color: #e5e5e5;
}

.button_menu {
  display: none;
}

.button_menu_input {
  opacity: 0;
}

.contacto a {
  color: #09010B;
}

html {
  scroll-behavior: smooth;
}


@media (max-width: 1700px) {

  html {
    scroll-behavior: smooth;
  }

  .menuDesplegableRevest ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 250px;
    left: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableRevest:hover>ul {
    display: block;
    width: 10%;
    right: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableRevest ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableRevest ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegable ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 250px;
    left: 42%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegable:hover>ul {
    display: block;
    width: 20%;
    right: 40%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegable ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegable ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegableDos ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 250px;
    left: 52%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableDos:hover>ul {
    display: block;
    width: 20%;
    right: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableDos ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableDos ul li:hover {
    background-color: #e5e5e5;
  }
}

@media (max-width: 1500px) {
  .menuDesplegableRevest ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 230px;
    left: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableRevest:hover>ul {
    display: block;
    width: 10%;
    right: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableRevest ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableRevest ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegable ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 230px;
    left: 42%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegable:hover>ul {
    display: block;
    width: 20%;
    right: 40%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegable ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegable ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegableDos ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 230px;
    left: 52%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableDos:hover>ul {
    display: block;
    width: 20%;
    right: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableDos ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableDos ul li:hover {
    background-color: #e5e5e5;
  }
}

@media (max-width: 1280px) {
  .navMenu {
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: row;
    background-color: white;
    padding: 0 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    justify-content: space-around;
    margin-bottom: 2%;
  }

  .navMenu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: row;
    width: 100%;
  }

  .navMenu ul li {
    list-style: none;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
    color: var(--colorNegro);
    text-transform: uppercase;
  }

  .navMenu ul li:hover {
    border-top: 1px black solid;
    color: var(--colorNegro);
    font-weight: 700;
  }

  .navMenu ul li:active {
    border-top: 2px black solid;
  }

  .button_menu {
    display: none;
  }

  .button_menu_input {
    opacity: 0;
  }

  .contacto a {
    color: #09010B;
  }

  html {
    scroll-behavior: smooth;
  }

  .menuDesplegableRevest ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 200px;
    left: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableRevest:hover>ul {
    display: block;
    width: 10%;
    right: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableRevest ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableRevest ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegable ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 200px;
    left: 42%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegable:hover>ul {
    display: block;
    width: 20%;
    right: 40%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegable ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegable ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegableDos ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 200px;
    left: 52%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableDos:hover>ul {
    display: block;
    width: 20%;
    right: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableDos ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableDos ul li:hover {
    background-color: #e5e5e5;
  }
}

@media (max-width: 1080px) {

  .navMenu {
    display: flex;
    align-items: center;
    margin: auto;
    flex-direction: row;
    background-color: white;
    padding: 0 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    justify-content: space-around;
    margin-bottom: 2%;
  }

  .navMenu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: row;
    width: 100%;
  }

  .navMenu ul li {
    list-style: none;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
    color: var(--colorNegro);
    text-transform: uppercase;
  }

  .navMenu ul li:hover {
    border-top: 1px black solid;
    color: var(--colorNegro);
    font-weight: 700;
  }

  .navMenu ul li:active {
    border-top: 2px black solid;
  }

  .button_menu {
    display: none;
  }

  .button_menu_input {
    opacity: 0;
  }

  .contacto a {
    color: #09010B;
  }

  html {
    scroll-behavior: smooth;
  }

  .menuDesplegableRevest ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 180px;
    left: 23%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableRevest:hover>ul {
    display: block;
    width: 10%;
    right: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableRevest ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableRevest ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegable ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 180px;
    left: 42%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegable:hover>ul {
    display: block;
    width: 20%;
    right: 40%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegable ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegable ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegableDos ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 180px;
    left: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableDos:hover>ul {
    display: block;
    width: 20%;
    right: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableDos ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableDos ul li:hover {
    background-color: #e5e5e5;
  }
}


@media (max-width: 800px) {
  .menu_container {
    height: 50px;
  }

  .button_menu {
    display: block;
  }

  .button_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 2%;
    color: #09010B;
    width: 60px;
    height: 40px;
    cursor: pointer;
    z-index: 5;
  }

  .button_menu_input {
    opacity: 0;
  }

  .button_menu_input:checked+.navMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0%;
    top: 25%;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;
    transform: translateX(0%);
    transition: all 1s ease;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0%;
    top: 25%;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;
    transform: translateX(-100%);
    transition: all 1s ease;
  }

  .navMenu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 100%;
  }

  .navMenu ul li {
    list-style: none;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
    color: var(--colorNegro);
    text-transform: uppercase;
  }

  .navMenu ul li:hover {
    background-color: rgb(185, 185, 185);
    color: var(--colorNegro);
    font-weight: 700;
  }

  .menuDesplegableRevest ul {
    display: none;
    min-width: 140px;
    position: relative;
    background-color: white;
    width: 50%;
    top: 0px;
    left: 00%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableRevest:hover>ul {
    display: block;
    width: 10%;
    right: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableRevest ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableRevest ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegable ul {
    display: none;
    min-width: 140px;
    position: relative;
    background-color: white;
    width: 50%;
    top: 0px;
    left: 00%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegable:hover>ul {
    display: block;
    width: 20%;
    right: 40%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegable ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegable ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegableDos ul {
    display: none;
    min-width: 140px;
    position: relative;
    width: 50%;
    top: 0;
    left: 00%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableDos:hover>ul {
    display: block;
    width: 20%;
    right: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableDos ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableDos ul li:hover {
    background-color: #e5e5e5;
  }
}

@media (max-width: 475px) {
  .menu_container {
    height: 50px;
  }

  .button_menu {
    display: block;
  }

  .button_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 2%;
    color: #09010B;
    width: 60px;
    height: 40px;
    cursor: pointer;
    z-index: 5;
  }

  .button_menu_input {
    opacity: 0;
  }

  .button_menu_input:checked+.navMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0%;
    top: 25%;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;
    transform: translateX(0%);
    transition: all 1s ease;
    text-align: justify;
  }

  .navMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0%;
    top: 25%;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 3;
    transform: translateX(-100%);
    transition: all 1s ease;
    text-align: justify;
  }

  .navMenu ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 100%;
    text-align: justify;
  }

  .navMenu ul li {
    list-style: none;
    padding: 8px;
    font-size: 12px;
    font-weight: 700;
    color: var(--colorNegro);
    text-transform: uppercase;
  }

  .navMenu ul li:hover {
    background-color: rgb(185, 185, 185);
    color: var(--colorNegro);
    font-weight: 700;
  }

  .menuDesplegableRevest ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 220px;
    left: 5%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    font-size: 12px;
  }

  .menuDesplegableRevest:hover>ul {
    display: block;
    width: 90%;
    right: 55%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
    font-size: 12px;
  }

  .menuDesplegableRevest li {
    font-size: 12px;
  }

  .menuDesplegableRevest ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
    font-size: 12px;
  }

  .menuDesplegableRevest ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegable ul {
    display: none;
    min-width: 140px;
    position: absolute;
    background-color: white;
    width: 50%;
    top: 250px;
    left: 5%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegable:hover>ul {
    display: block;
    width: 90%;
    right: 40%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegable ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegable ul li:hover {
    background-color: #e5e5e5;
  }

  .menuDesplegableDos ul {
    display: none;
    min-width: 140px;
    position: absolute;
    width: 50%;
    top: 280px;
    left: 5%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  .menuDesplegableDos:hover>ul {
    display: block;
    width: 90%;
    right: 30%;
    transition: all 1s ease;
    z-index: 100000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-align: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: column;
  }

  .menuDesplegableDos ul li {
    border-bottom: solid 1px black;
    position: relative;
    cursor: pointer;
    padding: 5px;
    top: 0px;
  }

  .menuDesplegableDos ul li:hover {
    background-color: #e5e5e5;
  }
}