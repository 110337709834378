.textoArriba {
  color: white;
  background-color: #09010B;
  width: 100%;
  height: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.textoArriba p {
  padding: 10px;
}

.contenedorLogo {
  width: 100%;
  border-bottom: 1px #979797 solid;
}

.contenedorLogo img {
  width: 30%;
  height: 20%;
}

.layout {
  width: 100%;
  background-color: #e5e5e5;
}

.mainTitle {
  padding-top: 20px;
  font-size: 20px;
  font-weight: 600;
}

.containerDatos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.datos {
  padding: 0.5rem;
  /* text-align: justify; */
}

.datos p {
  font-weight: 600;
  /* text-align: justify; */
}

.datos ul {
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: row;
  margin-top: 2%;
}

.datos li {
  margin-top: 8%;
}

.datos a {
  color: #fff;
  font-size: 20px;
}

.datos h3 {
  text-transform: uppercase;
  font-weight: 600;
  /* text-align: justify; */
}

.info {
  text-align: center;
  font-family: var(--fuenteLetras);
  font-weight: 400;
  color: var(--colorNegro);
  font-size: 18px;
  width: 100%;
  padding: 1.5rem;
  margin-top: 50px;
  margin-bottom: -70px;
}

.form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
  border: solid 2px transparent;
  padding: 3.5em 1em;
  margin-top: -10px;
  /* background-color: #09010B; */
}

.formTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 900;
  font-family: var(--fuenteLetras);
  margin-bottom: 0.4em;
}

.textRequired {
  text-align: center;
  font-family: var(--fuenteLetras);
  font-weight: 900;
  color: var(--colorNegro);
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  font-weight: 400;
}

.fb a {
  width: 50px;
  height: 40px;
  color: black;
  /* border: solid 1px white; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  /* background-color: #4267B2; */
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 30px;
}

.fb:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease;
  /* background-color: #4267b2b5; */
}

.ig a {
  width: 50px;
  height: 40px;
  /* border: solid 1px white; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  /* background-color: #997db9; */
  border-radius: 5px;
  transition: all 0.3s ease;
  color: black;
  font-size: 30px;
}

.ig:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease;
  /* background-color: #997db9b8; */
}

.wsp a {
  width: 50px;
  height: 40px;
  /* border: solid 1px white; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  /* background-color: #25d366; */
  border-radius: 5px;
  transition: all 0.3s ease;
  color: black;
  font-size: 30px;
}

.wsp:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease;
  /* background-color: #25d365b4; */
}

.inputsContacto {
  font-family: var(--fuenteLetras);
  text-align: justify;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.8em 1em;
  margin: auto;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
  min-width: 300px;
  width: 90%;
  background-color: #e5e5e5;
  color: #09010B;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;

}

.inputTextarea {
  font-family: var(--fuenteLetras);
  text-align: justify;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.8em 1em;
  margin: auto;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
  min-width: 300px;
  width: 90%;
  background-color: #e5e5e5;
  color: #09010B;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
  resize: none;
}

.buttonSend {
  width: 15%;
}

@media (max-width: 475px) {

  .info {
    text-align: center;
    font-family: var(--fuenteLetras);
    font-weight: 400;
    color: var(--colorNegro);
    font-size: 18px;
    width: 100%;
    padding: 1.5rem;
    margin-bottom: -40px;
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2.5em 1em;

  }

  .inputsContacto {
    width: 50%;
  }

  .inputTextarea {
    width: 50%;
  }

  .buttonSend {
    margin-top: 20px;
    width: 50%;
  }
}