* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}

.layout {
  width: 100%;
  background-color: #e5e5e5;
}

.textoArriba {
  color: white;
  background-color: #09010B;
  width: 100%;
  height: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.textoArriba p {
  padding: 10px;
}

.contenedorLogo {
  width: 100%;
  border-bottom: 1px #979797 solid;
}

.contenedorLogo img {
  width: 30%;
  height: 20%;
}

.contenedorProducto {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
}

.titulo h1 {
  padding-top: 20px;
  font-family: var(--fuenteLetras);
  text-transform: uppercase;
}

/* .bannerMembranas img {
  width: 80%;
  margin-top: 30px;
  min-width: 250px;
} */
.bannerFondoMembrana {
  background-image: url('../../img/bannerMembranas.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin: auto;
  margin-top: 20px;
}

.bannerFondoAccesorios {
  background-image: url('../../img/bannerAccesorios.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin: auto;
  margin-top: 20px;
}

.bannerFondoEnduidos {
  background-image: url('../../img/bannerEnduidos.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin: auto;
  margin-top: 20px;
}

.bannerFondoFijadores {
  background-image: url('../../img/bannerFijadores.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 400px;
  margin: auto;
  margin-top: 20px;
}

.info {
  width: 80%;
  text-align: justify;
  margin: auto;
  margin-top: 5%;
  font-family: var(--fuenteLetras);
}

.info h3 {
  font-size: 20px;
  font-family: var(--fuenteLetras);
}

.info p {
  font-size: 16px;
  font-family: var(--fuenteLetras);
  margin-bottom: 2%;
}

.arrow_faq {
  position: relative;
  top: 5px;
}

.labelFichaTecnica h3 {
  width: 80%;
  height: 40px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: grey;
  transform: rotate(0deg);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 0px 10%;
  text-align: justify;
  font-family: var(--fuenteLetras);
  cursor: pointer;
}

.checkbox_faq {
  opacity: 0;
}

.checkbox_faq:checked+.faq_answered {
  width: 90%;
  height: 100%;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.02em;
  transform: rotate(0deg);
  flex: none;
  order: 0;
  flex-grow: 0;
  transition: all 0.5s ease;
  padding: 8px;
  overflow: hidden;
  opacity: 1;
  transform: translateY(0);
  margin: auto;
}

.faq_answered {
  opacity: 0;
  transition: all 0.5s ease;
  width: 90%;
  height: 0;
  overflow: hidden;
}

.faq_answered img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
  height: 100%;
}

/* LOADER */

.loaderfondo {
  background-color: #09010B;
  width: 99vw;
  height: 100vh;

}

.loader {
  /* color of spining  */
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #09010B;
  color: #BB1818;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #BB1818;
}

.loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.loader:after {
  border: 10px solid #979797;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@media (max-width: 475px) {
  .textoArriba {
    color: white;
    background-color: #09010B;
    width: 100%;
    height: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    flex-direction: row;
  }

  .contenedorLogo {
    width: 100%;
    border-bottom: 1px #979797 solid;
  }

  .contenedorLogo img {
    width: 60%;
    height: 10%;
  }

  .labelFichaTecnica h3 {
    width: 80%;
    height: 40px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: grey;
    transform: rotate(0deg);
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px 0px 10%;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }

  .checkbox_faq {
    opacity: 0;
  }

  .checkbox_faq:checked+.faq_answered {
    width: 98%;
    height: 100%;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.02em;
    transform: rotate(0deg);
    flex: none;
    order: 0;
    flex-grow: 0;
    transition: all 0.5s ease;
    padding: 8px;
    overflow: hidden;
    opacity: 1;
    transform: translateY(0);
    margin: auto;
  }

  .faq_answered {
    opacity: 0;
    transition: all 0.5s ease;
    width: 90%;
    height: 0;
    overflow: hidden;
  }

  .faq_answered img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 90%;
    height: 100%;
  }

  /* LOADER */

  .loaderfondo {
    background-color: #09010B;
    width: 99vw;
    height: 100vh;

  }

  .loader {
    /* color of spining  */
    width: 50px;
    height: 50px;
    position: absolute;
    top: 45%;
    left: 45%;
    background-color: #09010B;
    color: #BB1818;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: #BB1818;
  }

  .loader:before {
    z-index: 100;
    animation: spin 1s infinite;
  }

  .loader:after {
    border: 10px solid #979797;
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .bannerFondoMembrana {
    background-image: url('../../img/bannerMembranas.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
    margin: auto;
    margin-top: 20px;
  }

  .bannerFondoAccesorios {
    background-image: url('../../img/bannerAccesorios.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
    margin: auto;
    margin-top: 20px;
  }

  .bannerFondoEnduidos {
    background-image: url('../../img/bannerEnduidos.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
    margin: auto;
    margin-top: 20px;
  }

  .bannerFondoFijadores {
    background-image: url('../../img/bannerFijadores.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 200px;
    margin: auto;
    margin-top: 20px;
  }
}