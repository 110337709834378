@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}

.textoArriba {
  color: white;
  background-color: #09010B;
  width: 100%;
  height: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.contenedorLogo {
  width: 100%;
  border-bottom: 1px #979797 solid;
}

.contenedorProducto {
  width: 98.5%;
  height: 100%;
  background-color: #e5e5e5;
  padding: 8px;
}

@keyframes scale {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.btnVolver {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 50px;
  width: 200px;
  height: 40px;
  border: 1px rgba(0, 0, 0, 0.406) solid;
  border-radius: 5px;
  margin-bottom: 2%;
}

.contenedorCard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: 80%;
  background-color: white;
  padding: 40px;
  display: flex;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  transform: scale(0);
  animation: scale 0.3s ease forwards;
}



.cardNombre {
  font-size: 50px;
  text-transform: uppercase;
  font-family: var(--fuenteMenu);
}

.cardImg {
  width: 30%;
  height: 30%;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 15px;
}


.cardCategoria {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-bottom: 2%;
  font-family: var(--fuenteMenu);
}

.cardCategoria b {
  font-size: 30px;
  font-weight: 700;
}

.cardSeccion {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  margin-bottom: 2%;
  font-family: var(--fuenteMenu);
}

.cardSeccion b {
  font-size: 30px;
  font-weight: 700;
}

.cardDescripcion {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
  font-weight: 400;
  text-align: justify;
  font-family: var(--fuenteMenu);
}

.cardDescripcion b {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}