@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}



.logo {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
  margin-bottom: 5%;
  border-bottom: 2px black solid;
}

.logo img {
  width: 20%;
}

.botonHomeClientes {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
  /* border-top: solid 2px black;
  border-left: solid 2px black;
  border-right: solid 2px black;
  border-bottom: solid 2px black; */
}


.title {
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 2%;
  font-weight: 700;
  font-family: var(--fuenteLetras);
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  width: 50%;
  height: 50%;
  border: solid 2px black;
  background-color: gainsboro;
  padding: 2.5rem 2rem;
  background-color: #e5e5e5;
  color: black;
}

.labelEmail,
.labelPass {
  font-size: 18px;
  color: black;

}

.inputEmail,
.inputPass {
  height: 30px;
  background-color: white;
  box-shadow: none;
  width: 250px;
  margin-bottom: 20px;
}

.buttonIngresar {
  width: 20%;
  transition: all 0.5s ease;
  background-color: #000000;
  color: white;
  border: solid 2px white;
}

.buttonIngresar:hover {
  background-color: white;
  color: #000000;
}

@media (max-width: 1380px) {

  .contenedorGeneral .logo img {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
  }

  .logo img {
    width: 20%;
  }

  .botonHomeClientes {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    /* border-top: solid 2px black;
    border-left: solid 2px black;
    border-right: solid 2px black;
    border-bottom: solid 2px black; */
  }


  .title {
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 15%;
    margin-bottom: 5%;
    font-weight: 700;
    font-family: var(--fuenteLetras);
  }

  .form {
    width: 90%;
    height: 90%;
    border: solid 2px black;
    background-color: gainsboro;
  }

  .labelEmail,
  .labelPass {
    font-size: 18px;
  }

  .inputEmail,
  .inputPass {
    height: 30px;
    background-color: white;
    box-shadow: none;
    width: 250px;
  }
}

@media (max-width: 1080px) {

  .contenedorGeneral .logo img {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
  }

  .logo img {
    width: 20%;
  }

  .botonHomeClientes {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    /* border-top: solid 2px black;
    border-left: solid 2px black;
    border-right: solid 2px black;
    border-bottom: solid 2px black; */
  }


  .title {
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 15%;
    margin-bottom: 5%;
    font-weight: 700;
    font-family: var(--fuenteLetras);
  }

  .form {
    width: 90%;
    height: 90%;
    border: solid 2px black;
    background-color: gainsboro;
  }

  .labelEmail,
  .labelPass {
    font-size: 18px;
  }

  .inputEmail,
  .inputPass {
    height: 30px;
    background-color: white;
    box-shadow: none;
    width: 250px;
  }
}

@media (max-width: 845px) {

  .contenedorGeneral .logo img {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
  }

  .logo img {
    width: 20%;
  }

  .botonHomeClientes {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    /* border-top: solid 2px black;
    border-left: solid 2px black;
    border-right: solid 2px black;
    border-bottom: solid 2px black; */
  }


  .title {
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 15%;
    margin-bottom: 5%;
    font-weight: 700;
    font-family: var(--fuenteLetras);
  }

  .form {
    width: 90%;
    height: 90%;
    border: solid 2px black;
    background-color: gainsboro;
  }

  .labelEmail,
  .labelPass {
    font-size: 18px;
  }

  .inputEmail,
  .inputPass {
    height: 30px;
    background-color: white;
    box-shadow: none;
    width: 250px;
  }
}

@media (max-width: 475px) {

  .contenedorGeneral .logo img {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
  }

  .logo img {
    width: 20%;
  }

  .botonHomeClientes {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    /* border-top: solid 2px black;
    border-left: solid 2px black;
    border-right: solid 2px black;
    border-bottom: solid 2px black; */
  }


  .title {
    font-size: 40px;
    text-transform: uppercase;
    margin-top: 15%;
    margin-bottom: 5%;
    font-weight: 700;
    font-family: var(--fuenteLetras);
  }

  .form {
    width: 90%;
    height: 90%;
    border: solid 2px black;
    background-color: gainsboro;
  }

  .labelEmail,
  .labelPass {
    font-size: 18px;
  }

  .inputEmail,
  .inputPass {
    height: 30px;
    background-color: white;
    box-shadow: none;
    width: 250px;
  }

  .buttonIngresar {
    width: 50%;
  }
}