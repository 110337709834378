.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  width: 100%;
  /* background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #c5ffb2 calc(40% + 1px), #c3ffb2 60%, #86ff5e calc(60% + 1px), #7eff5e 72%, #5eff3e calc(72% + 1px), #65ff3e 100%); */
  background-color: #e5e5e5;
}

.inputFichas[type="file" i] {
  display: flex;
  flex-direction: column;
  margin: auto;
  min-width: 300px;
  width: 30%;
  text-align: center;
  margin-top: 20px;
  height: 40px;
  background-color: var(--colorBlanco);
  font-family: var(--fuenteMenu);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 700;
  background-color: #e5e5e5;
  color: #09010B;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
  padding: 8px;
}

.selectorFichas {
  width: 20%;
  height: 30px;
  font-weight: 700;
}

.barradecarga {
  margin-top: 5px;
  width: 20%;
}

.labelSeccion {
  margin-top: 10px;
  font-weight: 700;
  margin-bottom: 10px;
}

.botonSubir {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contenedorFichasHechas {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  margin-top: 50px;
  flex-wrap: wrap;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contenedorFichasHechas img {
  width: 600px;
  height: 600px;
}

.sinImagen {
  width: 600px;
  height: 600px;
  border: solid 1px black;
}

.ficha {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-bottom: 2rem;
}

.ficha img {
  width: 600px;
  height: 600px;
}

.ficha button {
  margin-top: 10px;
}

@media (max-width: 475px) {

  .inputFichas[type="file" i] {
    display: flex;
    flex-direction: column;
    margin: auto;
    min-width: 300px;
    width: 80%;
    text-align: center;
    margin-top: 20px;
    height: 40px;
    background-color: var(--colorBlanco);
    font-family: var(--fuenteMenu);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 700;
    background-color: #e5e5e5;
    color: #09010B;
    box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
    padding: 8px;
  }

  .barradecarga {
    margin-top: 5px;
    width: 80%;
  }

  .selectorFichas {
    width: 80%;
    height: 30px;
    font-weight: 700;
  }

  .botonSubir {
    width: 80%;
    font-size: 15px;
  }

  .ficha img {
    width: 90%;
    height: 90%;
  }

}