* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}

.contenedorLanding {
  min-width: 375px;
}

.textoArriba {
  color: white;
  background-color: #09010B;
  width: 100%;
  height: 30px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.textoArriba p {
  padding: 10px;
}

.contenedorLogo {
  width: 100%;
  border-bottom: 1px #979797 solid;
}

.contenedorLogo img {
  width: 30%;
  height: 20%;
}

.carouselImg {
  margin: auto;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  margin-top: 0px;
  border: transparent 1px black;
  z-index: -1;
  background-color: #e5e5e5;
}

.carouselImg ul {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
  border: transparent 1px black;
}


.carouselImg li {
  list-style: none;
  width: 100%;
  height: 100%;
  border: transparent 1px black;
}

.carouselImg li:nth-child(1) {
  width: 100%;
  height: 500px;
  background-image: url('../../img/bannerprincipal.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: transparent 1px black;
}

.carouselImg img {
  width: 100%;
  height: 50%;
  border-right: 2px solid black;
}

@keyframes cambio {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -100%;
  }
}


.title_destacados {
  font-size: 30px;
  font-weight: 700;
  font-family: var(--fuenteMenu);
  padding: 20px;
}

.seccionQuienesSomos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 99%;
  height: 100%;
  background-color: #e5e5e5;
  align-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

.seccionQuienesSomos img {
  width: 50%;
}

.titulo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: var(--fuenteLetras);
  line-height: 20px;
  background-color: #e5e5e5;
  margin-top: 2%;
  text-align: center;
}

.titulo span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  padding: 10px;
  text-align: justify;
  margin: auto;
  width: 70%;
  font-size: 16px;
  margin-top: 3%;
  font-family: var(--fuenteLetras);
}

.quienessomos {
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 2%;
  text-align: center;
}


.aclaracion {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--fuenteMenu);
}

.contenedorProductos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  width: 98%;

  padding: 10px;
  margin-top: 30px;
  background-color: #edebe5;
}

/* LOADER */

.loaderfondo {
  background-color: #09010B;
  width: 99vw;
  height: 100vh;
}

.loader {
  /* color of spining  */
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #09010B;
  color: #BB1818;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #BB1818;
}

.loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.loader:after {
  border: 10px solid #979797;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 1280px) {
  .carouselImg {
    margin: auto;
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 0px;
    border: transparent 1px black;
    z-index: -1;
    background-color: #e5e5e5;
  }

  .carouselImg ul {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 0;
    border: transparent 1px black;
  }


  .carouselImg li {
    list-style: none;
    width: 100%;
    height: 100%;
    border: transparent 1px black;
    min-width: 800px;
  }

  .carouselImg li:nth-child(1) {
    width: 100%;
    height: 300px;
    background-image: url('../../img/bannerprincipal.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: transparent 1px black;
  }
}


@media (max-width: 1080px) {
  .carouselImg {
    margin: auto;
    min-width: 100%;
    width: 100%;
    overflow: hidden;
    margin-top: 0px;
    border: transparent 1px black;
    z-index: -1;
    background-color: #e5e5e5;
  }

  .carouselImg ul {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 0;
    border: transparent 1px black;
  }

  .carouselImg li {
    list-style: none;
    width: 100%;
    height: 100%;
    border: transparent 1px black;
    min-width: 800px;
  }

  .carouselImg li:nth-child(1) {
    width: 100%;
    height: 300px;
    background-image: url('../../img/bannerprincipal.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: transparent 1px black;
  }

}

@media (max-width: 800px) {
  .textoArriba {
    color: white;
    background-color: #09010B;
    width: 100%;
    height: 30px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .textoArriba p {
    padding: 10px;
  }

  .carouselImg {
    margin: auto;
    width: 100%;
    overflow: hidden;
    margin-top: 0px;
    border: transparent 1px black;
    z-index: -1;
    background-color: #e5e5e5;
  }

  .carouselImg ul {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 0;
    border: transparent 1px black;
  }


  .carouselImg li {
    list-style: none;
    width: 100%;
    height: 100%;
    border: transparent 1px black;
  }

  .carouselImg li:nth-child(1) {
    width: 100%;
    height: 300px;
    background-image: url('../../img/bannerprincipal.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: transparent 1px black;
  }



  @keyframes cambio {
    0% {
      margin-left: 0;
    }

    100% {
      margin-left: -100%;
    }
  }

  .seccionQuienesSomos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 99%;
    height: 100%;
    background-color: #e5e5e5;
    align-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .seccionQuienesSomos img {
    width: 50%;
  }
}

@media (max-width: 475px) {
  .textoArriba {
    color: white;
    background-color: #09010B;
    width: 100%;
    height: 30px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    flex-direction: row;
  }


  .contenedorLogo {
    width: 100%;
    border-bottom: 1px #979797 solid;
  }

  .contenedorLogo img {
    width: 60%;
    height: 10%;
  }

  .carouselImg {
    margin: auto;
    min-width: 250px;
    width: 100%;
    overflow: hidden;
    margin-top: 0px;
    border: transparent 1px black;
    z-index: -1;
    background-color: #e5e5e5;
  }

  .carouselImg ul {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-width: 375px;
    padding: 0;
    border: transparent 1px black;
  }

  .carouselImg li {
    list-style: none;
    min-width: 375px;
    width: 100%;
    height: 100%;
    border: transparent 1px black;
    min-width: 800px;
  }

  .carouselImg li:nth-child(1) {
    width: 100%;
    min-width: 375px;
    height: 200px;
    background-image: url('../../img/bannerprincipal.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: transparent 1px black;
  }

  .seccionQuienesSomos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 90%;
    height: 100%;
    background-color: #e5e5e5;
    align-content: center;
    margin-top: 2%;
    margin-bottom: 2%;
    color: #09010B;
  }

  .seccionQuienesSomos img {
    width: 100%;
  }


  .titulo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-family: var(--fuenteLetras);
    line-height: 20px;
    margin-top: 2%;
    text-align: center;
  }

  .titulo span {
    padding: 10px;
    text-align: justify;
    margin: auto;
    width: 70%;
    font-size: 12px;
  }

  .quienessomos {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2%;
    text-align: center;
  }


  .aclaracion {
    font-size: 13px;
    font-weight: 600;
    font-family: var(--fuenteMenu);
  }


  /* LOADER */

  .loaderfondo {
    background-color: #09010B;
    width: 99vw;
    height: 100vh;
  }

  .loader {
    /* color of spining  */
    width: 50px;
    height: 50px;
    position: absolute;
    top: 45%;
    left: 45%;
    background-color: #09010B;
    color: #BB1818;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: #BB1818;
  }

  .loader:before {
    z-index: 100;
    animation: spin 1s infinite;
  }

  .loader:after {
    border: 10px solid #979797;
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}