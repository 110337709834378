@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}


.accionesAdmin {
  margin-top: 2%;
  background-color: #fff;

}



.titSubir {
  text-transform: uppercase;
  color: var(--colorNegro);
  padding-bottom: 20px;
}

.formContainer {
  margin: auto;
  width: 100%;
}

.form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  margin: auto;
  width: 100%;
  height: 100%;
  border: solid 2px transparent;
  /* background-color: #fff; */
  background-color: #e5e5e5;
  /* background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #fff7b2 calc(40% + 1px), #fffbb2 60%, #fff25e calc(60% + 1px), #fff25e 72%, #ffe53e calc(72% + 1px), #fffc3e 100%); */
  padding: 50px 20px 50px 20px;
}

.form label {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  color: var(--colorNegro);
  padding-top: 10px;
  font-size: 18px;
  font-family: var(--fuenteLetras);
}

.form input {
  font-family: var(--fuenteLetras);
  text-align: justify;
  font-weight: 900;
  font-size: 1rem;
  padding: 0.8em 1em;
  margin: auto;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
  min-width: 300px;
  width: 90%;
  background-color: #e5e5e5;
  color: #09010B;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
}

.form input::placeholder {
  color: #09010B;
}

.form span {
  color: red;
  font-size: 16px;
  font-weight: 700;

}


input[type="file" i] {
  display: flex;
  flex-direction: column;
  margin: auto;
  min-width: 300px;
  width: 90%;
  text-align: center;
  height: 55px;
  background-color: var(--colorBlanco);
  font-family: var(--fuenteMenu);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 700;
  background-color: #e5e5e5;
  color: #09010B;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
}

.divtextarea {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.form textarea {
  resize: none;
  padding: 1.8em 1em;
  margin-bottom: 0.5em;
  margin-top: 1em;
  min-width: 300px;
  width: 50%;
  height: 60%;
  padding: 10px;
  background-color: #e5e5e5;
  color: #09010B;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--fuenteLetras);
  letter-spacing: 0.5px;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
}

.form select {
  margin-top: 10px;
  margin-bottom: 20px;
  min-width: 300px;
  width: 90%;
  height: 30px;
  text-align: center;
  background-color: #fff;
  box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
  font-size: 15px;
  font-weight: 700;
}



.form button {
  margin: auto;
  min-width: 200px;
  width: 1200px;
  width: 20%;
  height: 30px;
  margin-top: 2%;
  background-color: var(--colorBlanco);
  border: solid #09010B 1px;
  font-family: var(--fuenteMenu);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.2s ease;
}

.form button:hover {
  background-color: royalblue;
  transition: all 0.2s ease;
}

.carga {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  margin-top: 5%;
}

.barradecarga {
  width: 90%;
}

.porcentaje {
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 475px) {
  .form {
    width: 90%;
  }

  .form input {
    width: 20%;
  }

  .form textarea {
    width: 20%;
  }

  .barradecarga {
    width: 60%;
  }

  .carga {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    margin-top: 5%;
  }

  .form button {
    margin: auto;
    width: 1200px;
    width: 20%;
    height: 30px;
    margin-top: 2%;
    background-color: var(--colorBlanco);
    border: solid #09010B 1px;
    font-family: var(--fuenteMenu);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 700;
    transition: all 0.2s ease;
  }

  input[type="file" i] {
    display: flex;
    flex-direction: column;
    margin: auto;
    min-width: 300px;
    width: 90%;
    text-align: center;
    height: 35px;
    background-color: var(--colorBlanco);
    font-family: var(--fuenteMenu);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 700;
    background-color: #e5e5e5;
    color: #09010B;
    box-shadow: inset rgb(168, 168, 168) 2px 2px 10px 2px;
  }
}