@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}


.contenedorGeneral {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 10vh;
  background-color: #a2a2a2;
  color: #09010B;
}

.contenedorGeneral nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: row;
}

.contenedorGeneral nav ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  list-style: none;
  padding: 10px;
  color: #09010B;
  font-size: 18px;
  font-family: var(--fuenteMenu);
  font-weight: 700;
  text-decoration: none;
}

.contenedorGeneral nav ul li:hover {
  border-top: 1px black solid;
}

.logout {
  font-size: 16px;
  color: red;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout:hover {
  border-top: solid 1px red;
  border-bottom: solid 1px red;
  transform: scale(1.1);
  transition: all 0.3s ease;
  color: red;
}

.contenedorGeneral navlink {
  color: white;
}

@media (max-width: 1080px) {
  .contenedorGeneral {
    width: 100%;
    height: 35%;
  }

  .contenedorGeneral nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: row;
  }

  .contenedorGeneral nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    list-style: none;
    padding: 3px;
    color: #09010B;
    font-size: 15px;
    font-family: var(--fuenteMenu);
    font-weight: 700;
    text-decoration: none;
  }
}

@media (max-width: 950px) {
  .contenedorGeneral {
    width: 100%;
    height: 35%;
  }

  .contenedorGeneral nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: row;
  }

  .contenedorGeneral nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    list-style: none;
    padding: 10px;
    color: #09010B;
    font-size: 12px;
    font-family: var(--fuenteMenu);
    font-weight: 700;
    text-decoration: none;
  }
}

@media (max-width: 845px) {
  .contenedorGeneral {
    width: 100%;
    height: 35%;
  }

  .contenedorGeneral nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
  }

  .contenedorGeneral nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    list-style: none;
    padding: 10px;
    color: #09010B;
    font-size: 12px;
    font-family: var(--fuenteMenu);
    font-weight: 700;
    text-decoration: none;
  }
}

@media (max-width: 475px) {
  .contenedorGeneral {
    width: 100%;
    height: 35%;
  }

  .contenedorGeneral nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
  }

  .contenedorGeneral nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    list-style: none;
    padding: 10px;
    color: #09010B;
    font-size: 12px;
    font-family: var(--fuenteMenu);
    font-weight: 700;
    text-decoration: none;
  }
}