@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}

.bodyEditar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #e5e5e5;
  padding: 10px;
  flex-direction: column;
  width: 50%;
  height: 40%;
  margin-top: 10%;
}

.bodyEditar h3 {
  margin-top: 2%;
  font-family: var(--fuenteLetras);
  font-weight: 700;
  font-size: 25px;
}

.inputEdit {
  width: 90%;
  margin-top: 20px;
}

.botonesEdit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5%;
  padding: 8px;
}

.botonEdit {
  font-weight: 700;
  padding: 8px;
  margin-right: 20%;
}

.botonEditCancel {
  font-weight: 700;
  padding: 8px;
}

.form {
  background-color: #e5e5e5;
  /* background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #B2FCFF calc(40% + 1px), #B2FCFF 60%, #5EDFFF calc(60% + 1px), #5EDFFF 72%, #3E64FF calc(72% + 1px), #3E64FF 100%); */
  color: #09010B;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 96%;
}

.btnBuscar {
  width: 100%;
  margin-bottom: 5%;
  border-radius: 0;
  background-color: #F9F9F9;
  font-family: var(--fuenteMenu);
  font-weight: 700;
  transition: all .2s ease;
}

.btnBuscar:hover {
  background-color: royalblue;
  transition: all .2s ease;
}

.btnRecargar {
  width: 100%;
  border-radius: 0;
  background-color: #F9F9F9;
}

.btnRecargar:hover {
  background-color: royalblue;
  transition: all .2s ease;
}

.contenedorBuscador {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  flex-direction: column;
  background-color: #e5e5e5;
  /* background-color: #e0cdea; */
  /* background: linear-gradient(125deg, #ECFCFF 0%, #ECFCFF 40%, #B2FCFF calc(40% + 1px), #B2FCFF 60%, #5EDFFF calc(60% + 1px), #5EDFFF 72%, #3E64FF calc(72% + 1px), #3E64FF 100%); */
  color: #09010B;
}

.contenedorBuscador h2 {
  font-size: 50px;
  margin-top: 5%;
  font-family: var(--fuenteLetras);
  font-weight: 700;
}

.inputS {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
  border-radius: 0;
  outline: none;
  border: solid transparent 2px;
  height: 30px;
  font-weight: 700;
  text-align: center;
}

.contenedorBotones {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
  padding: 20px;
}

.contenedorProductos {
  padding: 10px;
  background-color: #e5e5e5;
}

.productos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  flex-wrap: wrap;
}

.btnModificar {
  font-size: 20px;
}



.loaderfondo {
  background-color: #e5e5e5;
  width: 100%;
  height: 50vh;
}

.loader {
  /* color of spining  */
  width: 50px;
  height: 50px;
  position: absolute;
  top: 80%;
  left: 50%;
  background-color: #09010B;
  color: #BB1818;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  /* margin-top: 25%; */
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #BB1818;
}

.loader:before {
  z-index: 100;
  animation: spin 1s infinite;
}

.loader:after {
  border: 10px solid #979797;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 475px) {
  .contenedorBuscador h2 {
    font-size: 25px;
  }
}