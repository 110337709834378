.contenedorProducto {
  width: 100%;
  margin: 5% 0;
}

.card {
  background-color: white;
  color: black;
  padding: 20px;
  font-size: 20px;
  width: 250px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  transition: all 0.2s ease;
}

.card:hover {
  box-shadow: 0 0 10px 5px gray;
  transition: all 0.2s ease;
}

.cardImg {
  width: 100%;
  height: 150px;
  margin: auto;
  min-width: 150px;
  margin-top: 0px;
}

.cardNombre {
  text-align: center;
  margin: auto;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px;
}

.cardCategoria {
  margin: auto;
  text-align: center;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 400;
  text-align: center;
}

.cardCategoria u {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0;
  text-align: center;
}

.cardSeccion {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: auto;
}

.cardSeccion u {
  font-weight: 700;
  font-size: 18;
  letter-spacing: 0;
  text-align: center;
}

.cardDescripcion {
  margin: auto;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 400;
  text-align: center;
  width: 220px;
}


.cardDescripcion u {
  font-weight: 700;
  font-size: 18;
  letter-spacing: 0;
  text-align: center;
}