:root {
  --colorVioleta: #9810B3;
  --colorNegro: #09010B;
  --colorBlanco: #F9F9F9;
  --colorRojo: #BB1818;

  --fuenteMenu: 'Montserrat', sans-serif;
  --fuenteBotones: 'Staatliches', cursive;
  --fuenteLetras: 'Roboto', sans-serif;
  --fuenteTitPrincipal: 'Saira', sans-serif;
}

.contenedorFooter {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  background-color: #000000;
  color: white;
  margin-bottom: -20%;
}

.footerItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 155px;
  margin-bottom: 10px;
}

.titulo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: var(--fuenteLetras);
  line-height: 20px;
  background-color: #000000;
  margin-top: 2%;
}

.titulo span {
  padding: 10px;
  text-align: center;
  margin: auto;
  width: 70%;
}

.quienessomos {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 2%;
}

.contacto {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
  margin-top: 2%;
  text-align: justify;
}

.contacto h2 {
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 18px;
  text-align: justify;
}

li {
  list-style: none;
}

.contacto ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
  text-align: justify;
}

.contacto li {
  margin-bottom: 5px;
  text-align: justify;
}

.contacto a {
  color: white;
  text-align: justify;
}

.inicio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
  margin-top: 5%;
}

.inicio h2 {
  margin-bottom: 10px;
}

.inicio ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
}

.inicio li {
  margin-bottom: 10px;
  font-size: 16px;
  color: white;
}

.inicio li:hover {
  padding-bottom: 2px;
  border-bottom: solid 1px white;
}

.redes {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.redes h2 {
  margin-bottom: 10px;
  display: none;
}

.redes ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: row;
  margin-top: 10px;
}

.redes li {
  font-size: 30px;
  padding: 10px;
}

.redes li:hover {
  border-bottom: solid 1px white;
}

.redes a {
  color: white;
}

.infoFinal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  flex-direction: column;
  width: 100%;
  height: 50px;
  color: rgb(170, 170, 170);
  background-color: rgb(64, 64, 64);
}

.infoFinal p {
  margin-left: 2%;
}

@media (max-width: 800px) {
  .infoFinal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 100%;
    height: 50px;
    color: rgb(170, 170, 170);
    background-color: rgb(64, 64, 64);
    font-size: 16px;
  }
}

@media (max-width: 475px) {
  .contenedorFooter {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    background-color: #000000;
    color: white;
    margin-bottom: -20%;
  }


  .titulo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-family: var(--fuenteLetras);
    line-height: 20px;
    background-color: #000000;
    margin-top: 2%;
    text-align: center;
  }

  .titulo span {
    text-align: justify;
    margin: auto;
    width: 70%;
    font-size: 12px;
  }

  .redes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    margin-top: -25%;
  }


  .redes ul {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    flex-direction: row;
  }

  .redes li {
    font-size: 30px;
    padding: 10px;
  }

  .redes li:hover {
    border-bottom: solid 1px white;
  }

  .redes a {
    color: white;
  }

  .infoFinal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    width: 100%;
    height: 50px;
    color: rgb(170, 170, 170);
    background-color: rgb(64, 64, 64);
    font-size: 12px;
  }

  .infoFinal p {
    margin-left: 2%;
  }

  .contacto {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    margin-top: 2%;
    text-align: justify;
  }

  .contacto ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
    text-align: justify;
  }
}